.headerNav {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    box-shadow: 0px 1px 8px #00589B22;
}
.bgfi .nav-link {
    font-weight: 300;
    font-size: 13pt;
    color: #00589B !important;
}

.bgfi .nav-link:hover {
    color: #949D88 !important;
}

#container-background{
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 500px;
}

.eer-footer{
    background-color: #00589B;
    color: #fff;
}

@font-face {
    font-family: Usenet;
    src: url('./assets/fonts/usenet.regular.ttf');
}

.primary-color{
    color: #00589B
}
.primary-color-dark{
    color: #001A49
}
.font-12{
    font-size: 12pt;
}
.font-14{
    font-size: 14pt;
}
.font-16{
    font-size: 16pt;
}
.font-18{
    font-size: 18pt;
}
.font-20{
    font-size: 20pt;
}

.font-22{
    font-size: 22pt;
}
.font-24{
    font-size: 24pt;
}
.font-26{
    font-size: 26pt;
}
.secondary-color{
    color: #A0AD8B
}

.usenet-font{
    font-family: "Usenet", "Helvetica", "Arial", "-apple-system";

}
.container-box .box:first-child:last-child {
    margin: 0 auto;
}
.accounts-type:first-child:last-child {
    margin: 0 auto;
}
.home-title{
    position: relative;
    font-weight: 500;
    font-size: 28pt;
}
.home-title:before{
    background-color: #A0AD8B;
    width: 55px ;
    position:absolute;
    content: '';
    height: 3px;
    bottom: -4px;
}

.eer-footer b{
    font-size: 13pt;
}
.eer-footer ul{
    padding-top:5px
}

.eer-footer ul li {
    padding-left: 8px;
}
.eer-footer ul li a {
    color: #fff;
}

.eer-footer ul li a:hover {
    color: #A0AD8B;
}

.main-message{
    height: auto;
    margin-top: 20px;
    padding: 15px;
    margin-bottom: 20px;
    min-height: 300px;
    background-color: #fff;
    border-radius: 15px;
}

.account-type-item{
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding:10px 10px 15px 10px;
    border: 1px solid #001A49;
    margin: 10px;
}

.account-type-item p{
    color:#001A49 !important;
    font-size: 1.1rem;
    font-weight:bold;
    line-height:100%;
    min-width: 100px;
    text-align: center;
}

@media only screen and (min-width: 320px)
and (max-width: 480px) {
    .account-type-item{
        font-size: 1rem;
        margin: 2px;
        min-width:88px
    }

    .account-type-item p{
        font-size: 1rem;
        min-width: 85px;
    }


    .accounts-type>div{
        font-size: 1rem;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

}
.account-type-item svg path{
    fill:#001A49 !important;
}

.account-type-item:hover {
    background-image: linear-gradient(35deg, rgba(0, 88, 155, 1), rgba(0, 26, 73, 1))
}

.account-type-item:hover p, .account-type-item:hover svg path{
    color: #fff !important;
    fill:#fff !important;
}

.account-details-block .account-info-content {
    margin: 15px;
    border-radius: 8px;
    color:#333333;
    max-height: 200px;
    overflow: scroll;
    padding: 10px;
    border: 1px solid #001A49
}

.account-type-item{
    cursor:pointer;
}

.step-breadcrumb .badge{
    font-size: 16pt;
    width: 38px;
    height: 38px;
}

.step-breadcrumb .step-breadcrumb-title {
    font-size: 10.5pt;
    padding-left: 10px;
}

.step-breadcrumb em{
    width: 50px;
    height: 3px;
    background-color: #00589B;
    margin-left: 5px;
    margin-bottom: 3px;
}

@media only screen and (max-width: 992px) {
    .to_display{
        display:inline-block;
    }

    .to_hide{
        display: none;
    }
}

.form-label{
    color:#333 !important;
}

.form-control {
    color:#555 !important;
}

.checkbox input{
    display: none;
}
.checkbox input:checked + label .account-type-item {
    background-image: linear-gradient(35deg, rgba(0, 88, 155, 1), rgba(0, 26, 73, 1))
}


.checkbox input:checked + label .account-type-item p {
    color: #fff !important;
 }

.checkbox input:checked + label .account-type-item svg path {
      fill:#fff !important;
}